<template>
<v-layout>
    <v-col class="align-card-font">
        <v-row>
            <label class="card-title-font">{{role.name}}</label>
        </v-row>
        <v-row>
            <label class="card-subtitle-font">{{role.description}}</label>
        </v-row>
    </v-col>
    <v-menu left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item>
                <action-button key="View" icon="mdi-eye" :show="isAllowed('viewRole')" @click="$router.push('/app/roles/view/'+role._id)"></action-button>
            </v-list-item>
            <v-list-item v-if="!role.systemGenerated && role.level>level||level==1">
                <action-button key="Edit" icon="mdi-pencil" :show="isAllowed('editRole')" @click="$router.push('/app/roles/'+role._id)"></action-button>
            </v-list-item>
            <v-list-item v-if="!role.systemGenerated && role.level>level||level==1">
                <action-button key="Delete" icon="mdi-delete" :show="isAllowed('deleteRole')"  @click="$emit('delete',role._id)"></action-button>
            </v-list-item>
            </v-list-item>
        </v-list>
    </v-menu>
</v-layout>
</template>

<script>
import ActionButton from '@/components/ActionButton'
export default {
    components: {
        ActionButton,
    },
    props: ["role","level"],
    methods: {
        completeAction(icon, id) {
            if (icon.name == "mdi-delete")
                this.$emit('delete', id)
            else
                this.performAction(icon,id) 
        }
    },
};
</script>

