<template>
<div>
    <div class="display-block">
        <alert-message v-if="msg" :error="msg"></alert-message>
        <page-header v-model="search" title="Roles" permission="createRole" @input="updateApiCall(apiCall,search)" @click="navigateTo('/app/roles/0')"></page-header>
    </div>
    <div>
        <v-data-table :headers="headers" :items="items" class="elevation-1" :search="search" hide-default-footer :items-per-page="items.length">
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <action-button key="View" icon="mdi-eye" :show="isAllowed('viewRole')" @click="$router.push('/app/roles/view/'+item._id)"></action-button>
                <action-button key="Edit" icon="mdi-pencil" v-if="!item.systemGenerated && item.level>level||level==1" :show="isAllowed('editRole')" @click="$router.push('/app/roles/'+item._id)"></action-button>
                <action-button key="Delete" icon="mdi-delete" v-if="!item.systemGenerated && item.level>level||level==1" :show="isAllowed('deleteRole')" @click="deleteRole(item._id)"></action-button>
            </template>
            <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.xsOnly">
                <role-card :role="item" :level="level" @delete="removeItem"></role-card>
            </template>
        </v-data-table>
        <pagination @input="initComponent()" ref="pagination" :api="apiCall" v-model="items" sort="+createdAt"></pagination>
    </div>
    <alert-message v-if="msg" :error="msg"></alert-message>
</div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import RoleCard from '@/components/RoleResponsiveCard'
export default {
    components: {
        ActionButton,
        RoleCard
    },
    data() {
        return {
            search: "",
            headers: [{
                    text: "Name",
                    value: "name"
                },
                {
                    text: "Description",
                    value: "description"
                },
                {
                    text: "Action",
                    value: "action",
                    align: "right"
                }
            ],
            level: 0,
            search: '',
            items: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/roles/view/",
                    permission: "viewRole"
                }, {
                    name: "mdi-pencil",
                    path: "/app/roles/",
                    permission: "editRole"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteRole"
                }
            ],
            apiCall:appConstants.ROLES_API
        };
    },
    methods: {
        async initComponent() {
            this.level = this.getUserProfile().level
        },
        async deleteRole(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Role?", appConstants.ROLES_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        },
        removeItem(id) {
            this.deleteRole(id)
        }
    }
};
</script>

<style scoped>
.format {
    padding: 1%;
}
</style>
